import React, { useState } from "react";
import { supabase } from "../supabase";
import { Link } from "react-router-dom";
import "./Auth.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordReset = async () => {
    setMessage("");
    setError("");

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://www.protaste.co.uk/dashboard", // Update this to your frontend's reset password URL
    });

    if (error) {
      setError(error.message);
    } else {
      setMessage(
        "A password reset email has been sent to your email address. Please check your inbox."
      );
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h1>Reset Your Password</h1>
        <p>Enter your email to reset your password.</p>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handlePasswordReset}>Send Reset Link</button>
        <p className="signup-link">
          Remembered your password? <Link to="/login">Login Here</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
