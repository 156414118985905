import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabase"; // Ensure this is the correct path to your Supabase client
import { loadStripe } from "@stripe/stripe-js";
import "./Subscribe.css"; // Custom CSS for styling

const Subscribe = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const stripePromise = loadStripe("pk_test_51QLTS2LG6q17U9EPtJsF1znXiuZkXjDDXmxdzA6BEbZ20apMSccz9Y4874qIxA40oeWadhr7VX4l7en3oM8YG8He00KzNIrxMy");

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error("Error fetching user:", error);
      } else {
        setUser(data.user);
      }
    };

    fetchUser();
  }, []);

  const createCheckoutSession = async (priceId) => {
    try {
      const response = await fetch("https://protaste.netlify.app/.netlify/functions/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
          userId: user.id,
        }),
      });

      const { id: sessionId } = await response.json();
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("There was an issue with the subscription. Please try again.");
    }
  };

  const handleMonthlySubscribe = () => {
    createCheckoutSession("price_1QLTT0LG6q17U9EPWmWwePPX");
  };

  const handleAnnualSubscribe = () => {
    createCheckoutSession("price_1QLTTKLG6q17U9EPd3OfiCX4");
  };

  const features = [
    "Weekly <strong>Meal Planning</strong>",
    "Automatic <strong>Shopping Lists</strong>",
    "Daily Updated <strong>Prices</strong>",
    "Custom <strong>Food Exclusions</strong>",
  ];

  return (
    <div className="subscribe-container">
      <h1>Choose Your Subscription Plan</h1>
      <div className="subscribe-plans">
        {/* Monthly Plan */}
        <div className="plan-card">
          <h2>Monthly Plan</h2>
          <p className="price">£5.99<span>/month</span></p>
          <ul>
            {features.map((feature, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: feature }} />
            ))}
          </ul>
          <button onClick={handleMonthlySubscribe} className="subscribe-button">Subscribe Monthly</button>
        </div>

        {/* Annual Plan */}
        <div className="plan-card">
          <h2>Annual Plan</h2>
          <p className="price">£63.50<span>/year</span></p>
          <p className="discount">Save 12% with annual payments</p>
          <ul>
            {features.map((feature, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: feature }} />
            ))}
          </ul>
          <button onClick={handleAnnualSubscribe} className="subscribe-button">Subscribe Annually</button>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
