import React, { useState } from "react";
import { supabase } from "../supabase";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";

const MAX_ATTEMPTS = 5; // Maximum login attempts
const LOCKOUT_TIME = 5 * 60 * 1000; // Lockout duration in milliseconds (5 minutes)

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const getLoginData = () => {
    const data = localStorage.getItem("loginData");
    return data ? JSON.parse(data) : { attempts: 0, lastAttempt: null };
  };

  const saveLoginData = (data) => {
    localStorage.setItem("loginData", JSON.stringify(data));
  };

  const handleLogin = async () => {
    const loginData = getLoginData();
    const now = Date.now();

    // Check if user is locked out
    if (loginData.lastAttempt && now - loginData.lastAttempt < LOCKOUT_TIME) {
      const remainingTime = Math.ceil((LOCKOUT_TIME - (now - loginData.lastAttempt)) / 1000);
      setErrorMessage(`Too many login attempts. Please try again in ${remainingTime} seconds.`);
      return;
    }

    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });

      if (error) {
        // Increment failed attempts and update localStorage
        loginData.attempts += 1;

        // Lock the user if maximum attempts reached
        if (loginData.attempts >= MAX_ATTEMPTS) {
          loginData.lastAttempt = now; // Start lockout timer
          setErrorMessage("Too many login attempts. Please try again in 5 minutes.");
        } else {
          setErrorMessage(`Invalid email or password. ${MAX_ATTEMPTS - loginData.attempts} attempts remaining.`);
        }

        saveLoginData(loginData);
      } else {
        // Reset attempts on successful login
        saveLoginData({ attempts: 0, lastAttempt: null });
        navigate("/dashboard");
      }
    } catch (err) {
      console.error("Error during login:", err);
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h1>Welcome Back to TastePro! 👋</h1>
        <p>Please log in to your account</p>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error */}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="password-container">
  <input
    type={showPassword ? "text" : "password"} // Toggle between text and password type
    placeholder="Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
  />
  <span
    className="toggle-password-icon"
    onClick={togglePasswordVisibility}
    aria-hidden="true"
  >
    <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
  </span>
</div>

        <button onClick={handleLogin}>Login Now</button>
        <p className="signup-link">
          Not Registered Yet? <Link to="/signup">Sign Up Now</Link>
        </p>
        <p className="forgot-password-link">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
