import React, { useEffect, useState } from "react";
import { supabase } from "../supabase";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAndSubscription = async () => {
      const { data: userData, error: userError } = await supabase.auth.getUser();

      if (userError || !userData.user) {
        console.error("Error fetching user or user not logged in:", userError);
        navigate("/login");
        return;
      }

      setUser(userData.user);

      const { data: subscriptionData, error: subscriptionError } = await supabase
        .from("subscriptions")
        .select("status")
        .eq("user_id", userData.user.id)
        .eq("status", "active");

      if (subscriptionError) {
        console.error("Error checking subscription:", subscriptionError);
      } else if (subscriptionData && subscriptionData.length > 0) {
        setHasSubscription(true);
      }

      setLoading(false);
    };

    fetchUserAndSubscription();
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="content">
      <h1>Welcome to Dashboard</h1>
      {hasSubscription ? (
        <p>Here is where your main content will go.</p>
      ) : (
        <p>You currently do not have an active subscription.</p>
      )}
    </div>
  );
};

export default Dashboard;
